import React from 'react';
import { graphql } from 'gatsby';
import {
  OrdnaQuery,
  ContentfulCard,
  ContentfulDialogContent,
  ContentfulTextList,
} from '../../graphql-types';

import SEO from '@components/seo';
import Layout from '@components/Layout/Layout';
import CardPackageInfo from '@components/Home/CardPackageInfo';
import Card, { CardImage } from '@components/Card';
import Grid, { Row } from '@components/Grid';
import Container from '@components/Container';
import BottomCta from '@components/Home/BottomCta';

import useDialogRoute from '@components/Dialog/useDialogRoute.hook';
import appenPages from '@src/data/appenPages';
import ContentfulDialog from '@src/components/Dialog/ContentfulDialog';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';
import * as styles from './ordna.module.scss';
import StickyFooter from '@components/StickyFooter/StickyFooter';
import SnapScrollContainer from '@src/components/SnapScrollContainer';
import ProductHero from '@src/components/ProductHero';
import { navigate } from 'gatsby';
import Footer from '@src/components/Footer/Footer';
import AppRatings from '@src/components/Home/AppRatings';
import useIsMobile from '../hooks/useIsMobile.hook';
import DownloadAppButton from '@src/components/DownloadAppButton';
import UspList, { UspListItem } from '@src/components/UspList/UspList';
import Badge from '@components/Badge/Badge';
import { AppDownloadUrl } from '@src/pageConstants';

type OrdnaProps = {
  data: OrdnaQuery;
};

function Ordna({ data }: OrdnaProps) {
  const {
    contentfulPage: { headLine, pageContent },
  } = data;

  const collectDialog = useDialogRoute('prisgaranti');
  const collectCard = pageContent[0] as ContentfulCard;
  const collectDialogContent = pageContent[1] as ContentfulDialogContent;
  const collectImg = useContentfulImage(collectCard.backgroundImage);

  const financialScoreCard = pageContent[2] as ContentfulCard;
  const financialScoreImg = useContentfulImage(
    financialScoreCard.backgroundImage,
  );

  const splitDialog = useDialogRoute('fasta-kostnader');
  const fixedCostsCard = pageContent[4] as ContentfulCard;
  const splitDialogContent = pageContent[5] as ContentfulDialogContent;
  const fixedCostsImg = useContentfulImage(fixedCostsCard.backgroundImage);

  const tagsDialog = useDialogRoute('etiketter');
  const tagsCard = pageContent[6] as ContentfulCard;
  const tagsDialogContent = pageContent[7] as ContentfulDialogContent;
  const receiptImg = useContentfulImage(tagsCard.backgroundImage);

  const spendMoneyCard = pageContent[8] as ContentfulCard;
  const spendMoneyImg = useContentfulImage(spendMoneyCard.backgroundImage);

  const uspList = (pageContent[9] as ContentfulTextList).advancedTextListItems;

  const isMobile = useIsMobile();

  return (
    <SnapScrollContainer>
      <Layout theme="white" subNavLinks={appenPages} customFooter={<></>}>
        <SEO title={headLine} />
        <Container>
          <ProductHero
            title="Ge din bank superkrafter"
            subtitle="Se vart dina pengar går"
            video={
              <video
                playsInline={true}
                loop={true}
                autoPlay={true}
                disableRemotePlayback
                muted
                className={styles.heroVideo}
              >
                <source
                  src="https://storage.googleapis.com/rocker-marketing-assets/rocker_connect.mp4"
                  type="video/mp4"
                />
              </video>
            }
          >
            <UspList items={uspList as UspListItem[]} />
            <DownloadAppButton />
            {!isMobile && <AppRatings />}
          </ProductHero>

          <Grid spacedRows>
            <Row>
              <Card
                title={spendMoneyCard.title}
                text={spendMoneyCard.subtitle}
                cta={spendMoneyCard.linkText}
                ctaHref={AppDownloadUrl}
                contentfulImg={spendMoneyImg}
                wide
                theme="purple-light"
                snap={true}
                badge={<Badge color="pink">Nyhet!</Badge>}
              />
            </Row>
            <Card
              title={collectCard.title}
              text={collectCard.subtitle}
              cta={collectCard.linkText}
              onClick={collectDialog.openDialog}
              snap={true}
              theme="soft"
            >
              <CardImage imageData={collectImg} />
            </Card>

            <Card
              title={fixedCostsCard.title}
              text={fixedCostsCard.subtitle}
              cta={fixedCostsCard.linkText}
              onClick={splitDialog.openDialog}
              theme="purple"
              snap={true}
              backdropImage={fixedCostsImg}
            ></Card>
            <Card
              title={financialScoreCard.title}
              text={financialScoreCard.subtitle}
              ctaElement={
                <DownloadAppButton buttonText={financialScoreCard.linkText} />
              }
              onClick={() => navigate(financialScoreCard.linkPath)}
              backdropImage={financialScoreImg}
              style={{ position: 'relative', overflow: 'hidden' }}
              theme="purple"
              snap={true}
            ></Card>
            <Card
              title={tagsCard.title}
              text={tagsCard.subtitle}
              cta={tagsCard.linkText}
              onClick={tagsDialog.openDialog}
              theme="soft"
              snap={true}
            >
              <CardImage imageData={receiptImg} />
            </Card>
          </Grid>
        </Container>
        <div className={styles.bottomContainer}>
          <CardPackageInfo />
          <BottomCta />
          <Footer />
        </div>
        <ContentfulDialog
          visible={collectDialog.isOpen}
          contentfulData={collectDialogContent}
          onClose={collectDialog.closeDialog}
        />
        <ContentfulDialog
          visible={splitDialog.isOpen}
          contentfulData={splitDialogContent}
          leonClose={splitDialog.closeDialog}
        />
        <ContentfulDialog
          visible={tagsDialog.isOpen}
          contentfulData={tagsDialogContent}
          onClose={tagsDialog.closeDialog}
        />

        <StickyFooter />
      </Layout>
    </SnapScrollContainer>
  );
}

export default Ordna;

export const query = graphql`
  query Ordna($locale: String) {
    contentfulPage(pageUrl: { eq: "/ordna" }, node_locale: { eq: $locale }) {
      headLine
      pageHeader
      pageHeaderDescription {
        pageHeaderDescription
      }
      heroImage {
        title
        gatsbyImageData(width: 1040)
      }
      pageContent {
        ... on ContentfulCard {
          id
          title
          subtitle
          backgroundImage {
            gatsbyImageData(width: 1040)
            title
          }
          linkText
          linkPath
        }
        ... on ContentfulTextList {
          listName
          advancedTextListItems {
            icon
            text
          }
        }
        ... on ContentfulDialogContent {
          id
          name
          textContent {
            textContent
          }
          questions {
            answer {
              answer
            }
            question
          }
          heading
          headerImage {
            title
            gatsbyImageData(width: 630)
          }
          ctaHref
          ctaText
          extraContent {
            ... on ContentfulTextList {
              id
              listName
              advancedTextListItems {
                icon
                text
              }
            }
            ... on ContentfulTextSection {
              title
              content {
                content
              }
            }
            ... on ContentfulFaqSection {
              id
              questions {
                answer {
                  answer
                }
                question
              }
              title
              contactEmail
            }
          }
        }
      }
    }
  }
`;
