export default [
  {
    name: 'Betala',
    href: '/betala',
  },
  {
    name: 'Spara',
    href: '/spara',
  },
  {
    name: 'Låna',
    href: '/lana',
  },
  {
    name: 'Ordna',
    href: '/ordna',
  },
];
