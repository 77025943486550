// extracted by mini-css-extract-plugin
export var backdrop = "Dialog-module--backdrop--2aa60";
export var beingDragged = "Dialog-module--beingDragged--7c689";
export var closeButton = "Dialog-module--closeButton--4b9bf";
export var ctaButton = "Dialog-module--ctaButton--e0784";
export var dialog = "Dialog-module--dialog--6aab4";
export var dialogBody = "Dialog-module--dialogBody--38b70";
export var dialogContent = "Dialog-module--dialogContent--553a5";
export var dialogHeader = "Dialog-module--dialogHeader--7aa99";
export var fadeIn = "Dialog-module--fadeIn--965d7";
export var markdown = "Dialog-module--markdown--00549";
export var noHeader = "Dialog-module--noHeader--2808f";
export var roundedBottom = "Dialog-module--roundedBottom--4888b";
export var scrollableArea = "Dialog-module--scrollableArea--8d34f";
export var slideIn = "Dialog-module--slideIn--881ba";
export var visible = "Dialog-module--visible--da79f";
export var wrapper = "Dialog-module--wrapper--b27fd";