// extracted by mini-css-extract-plugin
export var body = "ProductHero-module--body--3aa35";
export var children = "ProductHero-module--children--65b4a";
export var image = "ProductHero-module--image--9e813";
export var imageContent = "ProductHero-module--imageContent--f6e6a";
export var leftAligned = "ProductHero-module--leftAligned--1b055";
export var rightAligned = "ProductHero-module--rightAligned--d406b";
export var scrollTextContainer = "ProductHero-module--scrollTextContainer--91411";
export var softTheme = "ProductHero-module--softTheme--f64c4";
export var subtitle = "ProductHero-module--subtitle--8ca29";
export var text = "ProductHero-module--text--0081a";
export var textContent = "ProductHero-module--textContent--9981f";
export var textHero = "ProductHero-module--textHero--2c3cf";
export var titleContainer = "ProductHero-module--titleContainer--2235d";
export var videoContent = "ProductHero-module--videoContent--68145";