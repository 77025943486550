import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useResizeDetector } from 'react-resize-detector';
import FocusTrap from 'focus-trap-react';

import classNames from 'classnames';
import Helmet from 'react-helmet';

import PrimaryButton from '@components/PrimaryButton/PrimaryButton';

import * as ThirdPartyTracking from '../../lib/thirdPartyTracking';

import * as styles from './Dialog.module.scss';
import { ContentfulImage } from '../../hooks/useContentfulImage.hook';
import BackdropImage from '@components/BackdropImage';
import { AppDownloadUrl } from '@src/pageConstants';

export type DialogProps = React.HTMLProps<HTMLDivElement> & {
  visible?: boolean;
  headerImage?: ContentfulImage;
  headerStyle?: React.CSSProperties;
  cta?: string;
  ctaHref?: string;
  onClose?: () => void;
};

function Dialog({
  children,
  onClose,
  headerImage,
  headerStyle,
  visible,
  cta,
  ctaHref,
  ...rest
}: DialogProps) {
  const dialogContainerRef = useRef();

  const [focusTrapActive, setFocusTrapActive] = useState(false);
  const [renderDialog, setRenderDialog] = useState(false);
  const [beingDragged, setBeingDragged] = useState(false);
  const doc = typeof document !== 'undefined' ? document.body : null;

  if (!doc) {
    return null;
  }

  const headerImg = headerImage ? (
    <BackdropImage
      noShading
      imageData={headerImage}
      loading="lazy"
      imgStyle={headerStyle}
    />
  ) : (
    undefined
  );

  useEffect(() => {
    if (visible) {
      const onKeyDown = e => {
        if (e.key === 'Escape') {
          onClose?.();
        }
      };

      document.addEventListener('keydown', onKeyDown);
      return () => {
        document.removeEventListener('keydown', onKeyDown);
      };
    }
  }, [visible]);

  useEffect(() => {
    if (visible) {
      setDragY(0);
      lastTouchY.current = 0;
      const timeout = setTimeout(() => setFocusTrapActive(true), 100);
      setRenderDialog(true);
      return () => clearTimeout(timeout);
    } else {
      setFocusTrapActive(false);
      const timeout = setTimeout(() => setRenderDialog(false), 330);
      return () => clearTimeout(timeout);
    }
  }, [visible]);

  const [roundedBottom, setRoundedBottom] = useState(false);
  const { ref: scrollContainer } = useResizeDetector<HTMLDivElement>({
    onResize: (_, height) => {
      setRoundedBottom(height < window.innerHeight - 79);
    },
  });

  const touchStart = e => {
    lastTouchY.current = e.nativeEvent.touches[0].screenY;
  };

  const touchEnd = () => {
    setBeingDragged(false);
    if (dragY > 120 && !movedUp.current) {
      onClose?.();
    } else {
      setDragY(0);
    }
  };

  const lastTouchY = useRef<number>(0);
  const [dragY, setDragY] = useState(0);
  const movedUp = useRef<boolean>(false);

  const touchMove = e => {
    const touch = e.nativeEvent.touches[0];
    if (scrollContainer.current && scrollContainer.current.scrollTop === 0) {
      const dy = touch.screenY - lastTouchY.current;
      movedUp.current = dy < 0;
      setDragY(Math.max(0, dragY + dy));
      setBeingDragged(true);
      lastTouchY.current = touch.screenY;
    } else {
      lastTouchY.current = touch.screenY;
      setBeingDragged(false);
      setDragY(0);
    }
  };

  if (!renderDialog) {
    return null;
  }

  const classes = classNames({
    [styles.wrapper]: true,
    [styles.visible]: visible,
    [styles.roundedBottom]: roundedBottom,
    [styles.noHeader]: !headerImage,
    [styles.beingDragged]: beingDragged,
  });

  const dialogContainerClicked = e => {
    if (e.target == dialogContainerRef.current) {
      onClose?.();
    }
  };

  return createPortal(
    <FocusTrap active={focusTrapActive}>
      <div className={classes} {...rest}>
        {visible && (
          <Helmet>
            <body />
          </Helmet>
        )}
        <div
          className={styles.backdrop}
          onClick={onClose}
          style={{
            transition: dragY > 0 && visible ? 'none' : undefined,
            opacity:
              dragY > 0 && visible
                ? Math.min(0.6, (1.0 - dragY / 256) * 0.6)
                : undefined,
          }}
        />
        <div
          className={styles.dialog}
          onClick={dialogContainerClicked}
          ref={dialogContainerRef}
        >
          <div
            className={styles.dialogContent}
            onTouchStart={touchStart}
            onTouchMove={touchMove}
            onTouchEnd={touchEnd}
            style={{
              transform:
                visible && dragY ? `translateY(${dragY}px)` : undefined,
              transition: visible && dragY > 0 ? 'none' : undefined,
            }}
          >
            <PrimaryButton
              className={styles.closeButton}
              icon="ChevronDown"
              theme="dark"
              aria-label="Stäng dialog"
              onClick={onClose}
            />
            <div className={styles.scrollableArea} ref={scrollContainer}>
              {headerImg && (
                <div className={styles.dialogHeader}>{headerImg}</div>
              )}
              <div className={styles.dialogBody}>
                {children}
                {cta && (
                  <PrimaryButton
                    className={styles.ctaButton}
                    href={ctaHref || AppDownloadUrl}
                    onClick={
                      !ctaHref ? ThirdPartyTracking.cardSignup : undefined
                    }
                  >
                    {cta}
                  </PrimaryButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </FocusTrap>,
    doc,
  );
}

export default Dialog;
