import React from 'react';

import * as styles from './ProductHero.module.scss';
import { Theme } from '../../styles/theme';
import classNames from 'classnames';
import Markdown from '../markdown';
import { ContentfulImage } from '@src/hooks/useContentfulImage.hook';
import { GatsbyImage } from 'gatsby-plugin-image';
import { ReactMarkdown } from 'react-markdown/lib/react-markdown';

type ProductHeroProps = Omit<React.HTMLProps<HTMLElement>, 'size'> & {
  title: string;
  subtitle?: string;
  body?: string;
  theme?: Theme;
  image?: ContentfulImage;
  video?: React.ReactNode;
  className?: string;
  flip?: boolean;
};

function ProductHero({
  title,
  subtitle,
  body,
  theme,
  image,
  video,
  children,
  className,
  flip = false,
  ...rest
}: ProductHeroProps) {
  const classes = classNames({
    [styles.textHero]: true,
    [styles.blackTheme]: theme === 'black',
    [styles.darkTheme]: theme === 'dark',
    [styles.softTheme]: theme === 'soft',
    [styles.leftAligned]: !flip, // use flipOrder to align text and images
    [styles.rightAligned]: flip, // add .rightAligned in your css
    [className]: className,
  });

  const textContentClasses = classNames({
    [styles.leftAligned]: !flip,
    [styles.textContent]: true,
  });

  const TextContent = (
    <div className={textContentClasses}>
      <div className={styles.titleContainer}>
        <h1>
          <ReactMarkdown>{title.replaceAll('<br>', '  \n')}</ReactMarkdown>
        </h1>
        {subtitle && (
          <Markdown className={styles.subtitle}>{subtitle}</Markdown>
        )}
      </div>
      {body && <Markdown className={styles.body}>{body}</Markdown>}
      {children && <div className={styles.children}>{children}</div>}
    </div>
  );

  const ImageContent = (
    <div className={styles.imageContent}>
      {video && <div className={styles.videoContent}>{video}</div>}
      {image && (
        <GatsbyImage
          image={image.imageData}
          alt={image.title}
          objectFit="cover"
          className={styles.image}
        />
      )}
    </div>
  );

  return (
    <header className={classes} {...rest}>
      {flip ? (
        <>
          {ImageContent}
          {TextContent}
        </>
      ) : (
        <>
          {TextContent}
          {ImageContent}
        </>
      )}
    </header>
  );
}

export default ProductHero;
