import React from 'react';
import * as styles from './StickyFooter.module.scss';
import { Rating } from '@mui/material';
import { StarBorder } from '@mui/icons-material';
import PrimaryButton from '@components/PrimaryButton/PrimaryButton';
import * as ThirdPartyTracking from '@lib/thirdPartyTracking';
import Logo from '../../images/rocker-badge-logo.svg';
import { useEffect, useState } from 'react';

type StickyFooterProps = {
  showAtScrollPosition?: number;
};
function StickyFooter({ showAtScrollPosition }: StickyFooterProps) {
  const queryString =
    typeof window !== 'undefined' ? window.location.search : '';
  const appDownloadUrl = `https://app.rocker.com/QyXr${queryString}`;

  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const snapScroll = document.querySelector('#scroll-snap-container');
    const showAt = showAtScrollPosition || 300;
    if (snapScroll) {
      snapScroll.addEventListener('scroll', () => {
        setShowFooter(snapScroll.scrollTop > showAt);
      });
    }
  }, []);

  return (
    <>
      {showFooter && (
        <div className={styles.footer}>
          <div className={styles.footerLogoContainer}>
            <img
              src={Logo}
              alt="Rocker Logo"
              className={styles.footerLogoImage}
            />
          </div>
          <div className={styles.footerTextAndButtonContainer}>
            <div>
              <div className={styles.footerText}>
                För en bättre ekonomisk hälsa
              </div>
              <Rating
                name="half-rating-read"
                value={4.5}
                precision={0.5}
                sx={{ color: '#FFEF5A', fontSize: '1rem' }}
                emptyIcon={
                  <StarBorder fontSize="inherit" className={styles.rating} />
                }
              />
            </div>
            <div className={styles.footerButtonContainer}>
              <PrimaryButton
                href={appDownloadUrl}
                className={styles.footerButton}
                theme="soft"
                style={{ color: 'black' }}
                onClick={() =>
                  ThirdPartyTracking.appBadgeDeepLinkClicked('', appDownloadUrl)
                }
              >
                Hämta
              </PrimaryButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default StickyFooter;
