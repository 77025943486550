import { navigate } from 'gatsby';

/**
 * useDialogRoute works as a toggle for dialogs etc.
 * it will append a search param to the current URL,
 * which enables closing of the dialog by pressing back
 * in the browser. Plus the dialog will stay open if the user refreshes the page
 * @param currentUrl the base path of the current page
 * @param dialogId
 * @returns
 */
const useDialogRoute = (dialogId?: string) => {
  const dialogPath = dialogId ? `dialog=${dialogId}` : 'dialog';
  const isOpen =
    typeof window !== 'undefined' &&
    window.location.search.includes(dialogPath);

  const openDialog = () => {
    if (isOpen) {
      return;
    }

    const params = new URLSearchParams(window.location.search);
    params.append('dialog', dialogId);
    navigate(`${window.location.pathname}?${params.toString()}`);
  };

  const closeDialog = () => {
    if (!isOpen) {
      return;
    }

    if (
      window.history.length > 1 &&
      document.referrer.indexOf(window.location.host) !== -1
    ) {
      navigate(-1);
    } else {
      const params = new URLSearchParams(window.location.search);
      params.delete('dialog');
      const searchParams = params.toString();
      const path = window.location.pathname;
      const url = searchParams ? `${path}?${searchParams}` : path;
      navigate(url, { replace: true });
    }
  };

  return {
    openDialog,
    closeDialog,
    isOpen,
  };
};

export default useDialogRoute;
