import React from 'react';

import Dialog, { DialogProps } from './Dialog';
import {
  ContentfulDialogContent,
  ContentfulFaqSection,
  ContentfulQuestion,
  ContentfulTextList,
  ContentfulTextSection,
} from '../../../graphql-types';

import Accordion from '../Accordion';
import Markdown from '../markdown';
import UspList, { UspListItem } from '../UspList/UspList';
import useContentfulImage from '@src/hooks/useContentfulImage.hook';

import * as styles from './Dialog.module.scss';

type ContentfulDialogProps = Omit<
  DialogProps,
  'headerImage' | 'ctaHref' | 'cta'
> & {
  contentfulData?: ContentfulDialogContent;
  uspList?: UspListItem[];
};

function FaqSection(questions: ContentfulQuestion[], key?) {
  return (
    <Accordion theme="soft" key={key}>
      {questions.map(q => (
        <Accordion.Item key={q.question} title={q.question}>
          <Markdown>{q.answer.answer}</Markdown>
        </Accordion.Item>
      ))}
    </Accordion>
  );
}

function ContentfulDialog({
  contentfulData,
  children,
  uspList,
  ...props
}: ContentfulDialogProps) {
  const img = contentfulData.headerImage
    ? useContentfulImage(contentfulData.headerImage)
    : null;
  return (
    <Dialog
      headerImage={img}
      headerStyle={{
        objectPosition: '50% 15%',
      }}
      cta={contentfulData.ctaText}
      ctaHref={contentfulData.ctaHref}
      {...props}
    >
      <h1>{contentfulData.heading}</h1>
      <Markdown>{contentfulData.textContent?.textContent}</Markdown>

      {uspList && <UspList items={uspList} style={{ marginBottom: 32 }} />}

      {contentfulData.extraContent?.map(s => {
        // Questions
        if ((s as any).questions) {
          const q = s as ContentfulFaqSection;
          return FaqSection(q.questions, q.title);
        }

        // USP list
        if ((s as any).advancedTextListItems) {
          const textList = s as ContentfulTextList;
          return (
            <UspList
              key={textList.listName}
              items={textList.advancedTextListItems}
              style={{ marginBottom: 32 }}
            />
          );
        }

        // Text section
        if ((s as any).content) {
          const textSection = s as ContentfulTextSection;
          return (
            <Markdown key={textSection.title} className={styles.markdown}>
              {textSection.content.content}
            </Markdown>
          );
        }

        return null;
      })}
      {children}
      {contentfulData.questions && FaqSection(contentfulData.questions)}
    </Dialog>
  );
}

export default ContentfulDialog;
